import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from "react-multi-lang";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import TextField from "../fields/TextField";
import Select from "../fields/Select";
import Checkbox from "../fields/Checkbox";
import fetchClient from "../utils/fetchClient";

const styles = (theme) => ({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    color: "black",
  },
});

class PersonalInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      selectedCountry:
        props.initialValues?.countryCode?.toUpperCase() || "",
    };
  }

  componentDidMount() {
    console.log("Valores iniciales en mount:", this.props.initialValues);
    
    if (this.props.initialValues?.countryCode) {
      this.setState({
        selectedCountry: this.props.initialValues.countryCode.toUpperCase(),
      });
    }

    fetchClient
      .get("availability/countries")
      .then((response) => {
        this.setState({ countries: response.data.data });
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.initialValues?.countryCode &&
      prevProps.initialValues?.countryCode !== this.props.initialValues.countryCode
    ) {
      console.log("Valores iniciales en update:", this.props.initialValues);
      this.setState({
        selectedCountry: this.props.initialValues.countryCode.toUpperCase(),
      });
    }
  }

  handleCountryChange = (event) => {
    const selectedCode = event.target.value.toUpperCase();
    this.setState({ selectedCountry: selectedCode });
  };

  render() {
    const { t, classes, initialValues } = this.props;
    const { countries, selectedCountry } = this.state;

    const effectiveSelectedCountry =
      selectedCountry ||
      initialValues?.countryCode?.toUpperCase() ||
      "";

    const required = (value) => (value ? undefined : t("arrival.Required"));

    const conditionalRequired = (value) => {
      if (!["ES", "SPAIN", "ESPAÑA"].includes(effectiveSelectedCountry)) {
        return undefined;
      }
      return value ? undefined : t("arrival.Required");
    };

    const parse = (value) => value.replace(/[^\d+\s]/g, "");

    const isPhoneValid = (value) => {
      if (!value) return t("arrival.Required");
      const phoneNumber = parsePhoneNumberFromString(value);
      return phoneNumber
        ? phoneNumber.isValid()
          ? undefined
          : t("arrival.InvalidPhone")
        : t("arrival.InvalidPhone");
    };

    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerLanguageCode"
                component={Select}
                label={t("arrival.Language")}
                validate={required}
              >
                <option value="EN">{t("languages.English")}</option>
                <option value="ES">{t("languages.Spanish")}</option>
                <option value="FR">{t("languages.French")}</option>
              </Field>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerFirstName"
                component={TextField}
                type="text"
                label={t("arrival.FirstName")}
                validate={required}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerLastName"
                component={TextField}
                type="text"
                label={t("arrival.LastName")}
                validate={required}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerRealEmail"
                component={TextField}
                type="text"
                label={t("arrival.Email")}
                validate={required}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerTel1"
                component={TextField}
                parse={parse}
                validate={isPhoneValid}
                label={t("arrival.Phone")}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="countryCode"
                component={Select}
                label={t("arrival.CountryCode")}
                validate={required}
                onChange={this.handleCountryChange}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.countryId.toUpperCase()}>
                    {country.countryName}
                  </option>
                ))}
              </Field>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            style={{
              display: ["ES", "SPAIN", "ESPAÑA"].includes(effectiveSelectedCountry)
                ? "block"
                : "none",
            }}
          >
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerCity"
                component={TextField}
                type="text"
                label={t("arrival.City")}
                validate={conditionalRequired}
              />
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            style={{
              display: ["ES", "SPAIN", "ESPAÑA"].includes(effectiveSelectedCountry)
                ? "block"
                : "none",
            }}
          >
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerProvince"
                component={TextField}
                type="text"
                label={t("arrival.Province")}
                validate={conditionalRequired}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerZip"
                component={TextField}
                type="text"
                label={t("arrival.ZipCode")}
                validate={required}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <Field
                fullWidth
                name="customerAddress"
                component={TextField}
                type="text"
                label={t("arrival.Address")}
                validate={required}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Field
              color="primary"
              label={t("arrival.MarketingConsent")}
              name="customerAllowContact"
              component={Checkbox}
              type="checkbox"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation(withStyles(styles)(PersonalInfoForm));
