import * as React from "react";
import MuiSelect from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const FormHelperTextWrapper = ({
  input: { name, value, onChange, ...restInput },
  meta,
  label,
  formControlProps,
  onChange: externalOnChange, // capturamos el onChange externo, si existe
  ...rest
}) => {
  const showError = Boolean((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error);

  const handleChange = event => {
    console.log("Select component onChange event:", event.target.value);
    // Llama al onChange de react-final-form para actualizar el valor del campo
    onChange(event);
    // Si se pasó un onChange externo, lo ejecuta también
    if (externalOnChange) {
      externalOnChange(event);
    }
  };

  return (
    <FormControl {...formControlProps} error={showError}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <MuiSelect
        {...rest}
        name={name}
        onChange={handleChange}
        inputProps={restInput}
        value={value}
      />
      {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};

export default FormHelperTextWrapper;
